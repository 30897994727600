import React, { useEffect, useRef, useState } from 'react'
import "./newuniverse.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const NewUniverse = () => {
    // const [circleStyle, setCircleStyle] = useState({
    //     opacity: 0,
    //     transform: 'translate(-50%, -50%) scale(0)',
    // });

    // const handleMouseMove = (e) => {
    //     const { left, top } = e.currentTarget.getBoundingClientRect();
    //     const x = e.clientX - left;
    //     const y = e.clientY - top;

    //     setCircleStyle({
    //         left: `${x}px`,
    //         top: `${y}px`,
    //         opacity: 1,
    //         transform: 'translate(-50%, -50%) scale(1)',
    //     });
    // };

    // const handleMouseLeave = () => {
    //     setCircleStyle((prevStyle) => ({
    //         ...prevStyle,
    //         opacity: 0,
    //         transform: 'translate(-50%, -50%) scale(0.5)',
    //         transition: 'opacity 0.4s ease, transform 0.4s ease',
    //     }));
    // };

    const [circleStyle, setCircleStyle] = useState({
        opacity: 0,
        left: '0',
        top: '0',
    });

    const [lastPosition, setLastPosition] = useState({ left: '0', top: '0' });

    const handleMouseMove = (e) => {
        const { left, top } = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - left;
        const y = e.clientY - top;

        setCircleStyle({
            left: `${x}px`,
            top: `${y}px`,
            opacity: 0.15,
        });


        setLastPosition({ left: `${x}px`, top: `${y}px` });
    };

    const handleMouseLeave = () => {
        setCircleStyle((prevStyle) => ({
            ...prevStyle,
            left: lastPosition.left,
            top: lastPosition.top,
            opacity: 0.15,
        }));
    };

    const handleMouseEnter = () => {
        setCircleStyle((prevStyle) => ({
            ...prevStyle,
            left: lastPosition.left,
            top: lastPosition.top,
            opacity: 0.15,
        }));
    };

    const textRefs = useRef([]);

    useEffect(() => {
      textRefs.current.forEach((text) => {
        gsap.fromTo(
          text,
          { y: '120%' },
          {
            y: '0%',
            duration: 2,
            ease: 'power3.out',
            scrollTrigger: {
              trigger: text,
              start: 'top 80%',  
              end: 'top 20%',   
              once: true       
            },
          }
        );
      });
    }, []);



    const imageRefs = useRef([]);

    useEffect(() => {
        imageRefs.current.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 4,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                    },
                }
            );
        });
    }, []);



    return (
        <>
            <section className="new-universe">
                <img src="\assets\newuniverse\bg-section.png" alt="img" className='img-fluid bg-section' />
                <img ref={(el) => (imageRefs.current[0] = el)} src="\assets\newuniverse\top-center-shadow.png" alt="img" className='img-fluid top-center-shadow' />
                <img ref={(el) => (imageRefs.current[1] = el)} src="\assets\newuniverse\top-center-shadow-mobile.png" alt="img" className='img-fluid top-center-shadow-mobile d-none' />
                <div className="custom-container">
                    <div className="parent" onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave} >
                        {/* <div className="shadow-circle" style={circleStyle}></div> */}
                        <div
                            className="shadow-circle"
                            style={{
                                left: circleStyle.left,
                                top: circleStyle.top,
                                opacity: circleStyle.opacity,
                            }}
                        />
                        <img ref={(el) => (imageRefs.current[2] = el)} src="\assets\newuniverse\bg-effect-dim.png" alt="img" className='img-fluid bg-effect-dim' />
                        {/* <img ref={(el) => (imageRefs.current[2] = el)} src="\assets\newuniverse\left-top-shadow.png" alt="img" className='img-fluid left-top-shadow' />
                        <img ref={(el) => (imageRefs.current[3] = el)} src="\assets\newuniverse\right-bottom-blue.png" alt="img" className='img-fluid right-bottom-blue' /> */}
                        <img src="\assets\newuniverse\percent-img.png" alt="img" className='img-fluid percent-img' />
                        <div className="main-content">
                            <p className="top-text">Community-owned and operated</p>
                            <div style={{ overflow: 'hidden', position: 'relative' }}>
                                <h4
                                    ref={(el) => (textRefs.current[0] = el)}
                                    style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }}
                                >
                                    Enter a new universe
                                </h4>
                            </div>
                            <div style={{ overflow: 'hidden', position: 'relative' }}>
                                <h4
                                    ref={(el) => (textRefs.current[1] = el)}
                                    style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }}
                                >
                                    of connected services
                                </h4>
                            </div>
                            {/* <p className='para'>Vion apps and services connect using IBC, the Inter-Blockchain Communication protocol. This innovation enables you to freely exchange assets and data across sovereign,</p> */}
                            <div className="price-div">
                                <p>Dynamic Staking Rewards</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                <h5 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[2] = el)}>Early Validators: 1.1x Rewards</h5>
                                </div>
                            </div>
                            <div className="price-div">
                                <p>Easy Validator Setup</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                <h5 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[3] = el)}>Complete in few commands</h5>
                                </div>
                            </div>
                            <a href="\Vionwhitepaper.pdf" target="_blank"className='white-btn hover-button onlyforwithhhf'>
                                <span className="button-text">Read Foundation Paper</span>
                                <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <g clip-path="url(#clip0_261_9)">
                                        <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33333M19.8334 14L15.1667 18.6667" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_261_9">
                                            <rect width="28" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg></span>
                                <span className="new-button-text" style={{ marginBottom: "12px" }}>Read Foundation Paper</span>
                                <span className="new-arrow" style={{ right: "14px" }}><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <g clip-path="url(#clip0_261_9)">
                                        <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33333M19.8334 14L15.1667 18.6667" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_261_9">
                                            <rect width="28" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg></span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewUniverse
