import React from 'react'
import "./learn.scss"
import Navbar from '../landing/header/Navbar'
import Build from '../landing/Buildthefuture/Build'
import Footer from '../landing/footer/Footer'
const Learn = () => {
    return (
        <>
            <Navbar />
            <section className='mainlearn'>
                <div className='custom-container'>
                    <h1 className='mainhead'>Intro to Vion </h1>
                </div>
            </section>
            <section className='parenttext'>
                <div className='custom-container'>
                    <div className='innertext'>
                        <p>Vion Blockchain is an advanced, decentralized, Layer 1 blockchain designed to empower a more secure and rewarding internet. Built as a <span className='green'>Turing-complete, EVM-compatible</span> environment, Vion provides a seamless infrastructure for creating, deploying, and automating decentralized applications (DApps) and digital asset management solutions. Vion operates without intermediaries, leveraging cryptographic trust mechanisms that empower users with full ownership of their data and digital interactions.</p>
                        <p>
                            Entirely <span className='green'>permissionless</span>, Vion invites any user to contribute to network security as a validator or node operator, democratizing participation in a global blockchain ecosystem. With an open-source architecture and a foundational Proof of Stake (PoS) consensus model, Vion offers energy efficiency, scalability, and security—all critical attributes in the evolution of blockchain.</p>
                        <p className='bold'>Experience a decentralized ecosystem where the power lies in the community’s hands. Vion is more than technology; it’s a vision for a truly open, transparent, and user-empowered internet.</p>

                        <h2 className='mainhead'>Learn About Vion Blockchain </h2>
                        <p>Vion Blockchain is engineered to address the limitations of current Web2 and Web3 systems by fostering inclusivity, scalability, and efficiency. Key aspects of the platform include:</p>
                        <ul>
                            <li>
                                <span>   Layer 1 Decentralized Infrastructure</span>: Vion operates as a foundational blockchain layer with <span>programmable</span><span> Turing-complete compatibility</span>, facilitating complex DApp and protocol development. Full compatibility with the EVM allow developers to deploy Solidity-based contracts and work with familiar development languages, reducing barriers to blockchain innovation.
                            </li>
                            <li><span>Proof of Stake (PoS) Consensus</span>: Vion’s PoS model enhances network efficiency and scalability while minimizing environmental impact. By relying on token staking rather than energy-intensive mining, Vion achieves faster transaction finality, lowers energy usage, and maintains high security by requiring validators to have a financial stake in the network’s integrity.</li>
                            <li><span>Validator Network and FLUID Staking</span>: Vion’s unique validator structure includes Standard, Genesis NFT, and FLUID Validators. Each validator type has tailored requirements, from minimal technical expertise to more flexible staking options that enable <span>liquid staking</span>. The FLUID staking system ensures that validators can maintain liquidity while participating in securing the Vion network.</li>
                            <li><span>Cross-Chain Interoperability</span>: To support a diverse digital ecosystem, Vion integrates with major chains like Ethereum, Polygon, and Binance Smart Chain (BSC), facilitating seamless asset transfers and multi-chain applications. This cross-chain bridge enables users to transfer assets between networks, extending Vion’s accessibility and compatibility.</li>
                            <li><span>Incentivized and Scalable DApp Environment</span>: Developers on Vion benefit from robust tooling, boilerplate templates, and an incentivized framework that rewards high-performing DApps with rebates and platform support. The platform’s <span> native token generator</span> and <span>decentralized launchpad</span> empower users to create and fund new digital assets without intermediaries, unlocking novel opportunities in decentralized finance (DeFi), tokenized assets, and community governance.</li>
                        </ul>
                        <p>Explore Vion Blockchain—where decentralization merges with innovation, redefining Web3’s potential with user-friendly validator access, environmental responsibility, and the tools needed to build a secure, interconnected, and decentralized future.</p>
                    </div>
                </div>
            </section>
            <Build />
            <Footer />
        </>
    )
}

export default Learn