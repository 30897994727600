import React from 'react'
import "./validators.scss";
import Accordion from 'react-bootstrap/Accordion';

import Navbar from '../landing/header/Navbar';
import Footer from '../landing/footer/Footer';
import Build from '../landing/Buildthefuture/Build';

const Validators = () => {
    return (
        <>

            <Navbar />
            <section className="validator-section">
                <div className='part1_section'>
                    <img src='\assets\Ellipsenew.png' className='imgelip img-fluid' />
                    <img src='\assets\star.png' className='star img-fluid' />
                    <div className="custom-container">
                        <div className='part1'>
                            <div className='left_side'>
                                <h1>Vion</h1>
                                <h2>Validators</h2>
                                <p>Aut pariatur quia sed sequi culpa vel quos beatae. Ut culpa alias id nisi velit qui omnis sequi sed tempora libero et voluptas porro et provident tempora. Eum accusantium rerum in dolor distinctio est placeat vero.</p>
                                <button>Become a validator <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <g clip-path="url(#clip0_1303_29)">
                                        <path d="M8.16663 14H19.8333M19.8333 14L15.1666 9.33333M19.8333 14L15.1666 18.6667" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1303_29">
                                            <rect width="28" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg></button>

                            </div>
                            <div className='right_side'>
                                <img src='\assets\part1img.png' className=' img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='part2_section'>
                    <img src='\assets\eliptwo.png' className='imgelip2 img-fluid' />
                    <div className="custom-container">
                        <div className='part2'>
                            <div className='right_side'>
                                <img src='\assets\part2img.png' className=' img-fluid' />
                            </div>
                            <div className='left_side'>
                                <h3>Our thesis</h3>
                                <h2>Validators form the backbone of <span>Vion’s</span>  network.</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                                <p>Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='part3_section'>
                    <img src='\assets\lineelip.png' className='lineelip' />
                    <img src='\assets\lastelip.png' className='lastelip' />
                    <img src='\assets\part3back.png' className='lastelip2' />
                    <div className="custom-container">
                        <div className='part3'>
                            <h1>Validator Rewards</h1>
                            <div className='inner_part3'>
                                <div className='card1'>
                                    <img src='\assets\oxxx.png' className='boxxcard' />
                                    <h2>Protocol Rewards</h2>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                                </div>
                                <div className='card1'>
                                    <img src='\assets\oxxx.png' className='boxxcard' />
                                    <h2>Staking</h2>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                                    <button>Learn More  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                        <g clip-path="url(#clip0_1182_4641)">
                                            <path d="M5.58337 9H13.0834M13.0834 9L10.0834 6M13.0834 9L10.0834 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1182_4641">
                                                <rect width="18" height="18" fill="white" transform="translate(0.333374)" />
                                            </clipPath>
                                        </defs>
                                    </svg></button>
                                </div>
                                <div className='card1'>
                                    <img src='\assets\oxxx.png' className='boxxcard' />
                                    <h2>Stake Pools</h2>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                                    <button>Learn More  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                        <g clip-path="url(#clip0_1182_4641)">
                                            <path d="M5.58337 9H13.0834M13.0834 9L10.0834 6M13.0834 9L10.0834 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1182_4641">
                                                <rect width="18" height="18" fill="white" transform="translate(0.333374)" />
                                            </clipPath>
                                        </defs>
                                    </svg></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='part4_section'>
                    <div className="custom-container">
                        <div className='part4'>
                            <h1>Validating on VION</h1>
                            <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                            <div>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><span>01. </span>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed.</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt. Lorem ipsum dolor sit amet est accusamus dolores qui delectus.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><span>02. </span>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed.</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt. Lorem ipsum dolor sit amet est accusamus dolores qui delectus.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><span>03. </span>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed.</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt. Lorem ipsum dolor sit amet est accusamus dolores qui delectus.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><span>04. </span>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed.</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt. Lorem ipsum dolor sit amet est accusamus dolores qui delectus.

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='part5_section'>
                    <img src='\assets\lineelip.png' className='lineelip' />
                    <img src='\assets\part3back.png' className='lastelip2' />
                    <img src='\assets\lastelip.png' className='lastelip' />
                    <div className="custom-container">
                        <div className='part5'>
                            <div className='innersection'>
                                <div className='card2'>
                                    <img src='\assets\twocard.png' className='imgcarddd' />
                                    <h1>Get started</h1>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt. </p>
                                    <button>Learn More<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g clip-path="url(#clip0_1182_5347)">
                                            <path d="M5.25 9H12.75M12.75 9L9.75 6M12.75 9L9.75 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1182_5347">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></button>
                                </div>
                                <div className='card2'>
                                    <img src='\assets\twocard.png' className='imgcarddd' />
                                    <h1>Validators explorer</h1>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt. </p>
                                    <button>Learn More<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g clip-path="url(#clip0_1182_5347)">
                                            <path d="M5.25 9H12.75M12.75 9L9.75 6M12.75 9L9.75 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1182_5347">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></button>
                                </div>
                            </div>
                            <div className='innersection'>
                                <div className='card2'>
                                    <img src='\assets\twocard.png' className='imgcarddd' />
                                    <h1>Server Program</h1>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt. </p>
                                    <button>Learn More<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g clip-path="url(#clip0_1182_5347)">
                                            <path d="M5.25 9H12.75M12.75 9L9.75 6M12.75 9L9.75 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1182_5347">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></button>
                                </div>
                                <div className='card2'>
                                    <img src='\assets\twocard.png' className='imgcarddd' />
                                    <h1>Delegation Program</h1>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt. </p>
                                    <button>Learn More<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g clip-path="url(#clip0_1182_5347)">
                                            <path d="M5.25 9H12.75M12.75 9L9.75 6M12.75 9L9.75 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1182_5347">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Build />
            <Footer />
        </>

    )
}

export default Validators