import React, { useEffect } from 'react'
import "./careers.scss"
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'
const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Navbar />
            <section className='maincareer'>
                <div className='custom-container'><h1 className='mainhead'>careers</h1></div>

            </section>
            <section className='job'>
                <div className='custom-container'>

                    <h2 className='jobhead'>Open Job Positions</h2>
                    <div className='parentjobbox'>
                    <Link to="/apply">
                        <div className='innerjobbox'>
                            <div className='innerparenttext'>
                                <div className='leftyyyyy'>
                                    <h3>Head of Marketing</h3>
                                    <p>We’re looking for Head of Marketing to join our team</p>
                                    <div className='buttontwice'>
                                        <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>100% Remote</button>
                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>Full time</button>
                                    </div>
                                </div>
                                <div className='rightttt'>
                                    <h6>Apply Now<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7 17L17 7M17 7H9M17 7V15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></h6>
                                </div>

                            </div>
                        </div>
                        </Link>
                        <div className='innerjobbox'>
                            <div className='innerparenttext'>
                                <div className='leftyyyyy'>
                                    <h3>Marketing Operations Manager</h3>
                                    <p>We’re looking for Marketing Operations Manager to join our team</p>
                                    <div className='buttontwice'>
                                        <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>On-Site</button>
                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>Full time</button>
                                    </div>
                                </div>
                                <div className='rightttt'>
                                    <h6>Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7 17L17 7M17 7H9M17 7V15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></h6>
                                </div>

                            </div>
                        </div>
                        <div className='innerjobbox'>
                            <div className='innerparenttext'>
                                <div className='leftyyyyy'>
                                    <h3>Head of PR and Communications</h3>
                                    <p>We’re looking for Head of PR and Communications to join our team</p>
                                    <div className='buttontwice'>
                                        <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>100% Remote</button>
                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>Full time</button>
                                    </div>
                                </div>
                                <div className='rightttt'>
                                    <h6>Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7 17L17 7M17 7H9M17 7V15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></h6>
                                </div>

                            </div>
                        </div>
                        <div className='innerjobbox'>
                            <div className='innerparenttext'>
                                <div className='leftyyyyy'>
                                    <h3>Head of Community</h3>
                                    <p>We’re looking for Head of Community to join our team</p>
                                    <div className='buttontwice'>
                                        <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>On-Site</button>
                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>Full time</button>
                                    </div>
                                </div>
                                <div className='rightttt'>
                                    <h6>Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7 17L17 7M17 7H9M17 7V15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></h6>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default Careers