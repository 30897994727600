import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from '../../utils/Environment';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const Finalize = ({ setCheckForms }) => {
    const [show, setShow] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [understand, setUnderstand] = useState(false);
    const [agree, setAgree] = useState(false);
    const [errors, setErrors] = useState({});

    const handleClose = () => setShow(() => {
        navigate("/");
    });
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const validateFields = () => {
        let errors = {};

        if (!confirmation) errors.confirmation = 'You must confirm that the information provided is true.';
        if (!understand) errors.understand = 'You must understand the consequences of providing false information.';
        if (!agree) errors.agree = 'You must agree to the terms of use and privacy policy.';

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateFields()) return;

        const data = JSON.parse(localStorage.getItem('applyForm')) || {};

        const finalize = {
            confirmation,
            understand,
            agree,
        };

        const updatedData = {
            ...data,
            finalize,
        };

        const config = {
            method: 'put',
            url: Environment.api_url2 + '/application',
            data: updatedData,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            await axios(config);
            localStorage.removeItem('applyForm');
            localStorage.removeItem('checkforms');
            localStorage.removeItem('currentStep');
            handleShow();
        } catch (error) {
            console.error(error);
            if (error.response) {
                const { data } = error.response;
                if (data.message) {
                    toast.error(data.message);
                }
                if (data.details) {
                    data.details.forEach((detail) => {
                        toast.error(detail);
                    });
                }
            } else {
                toast.error('Error submitting form. Please try again.');
            }
            // toast.error('Error submitting form. Please try again.');
        }
    };

    return (
        <>
            <ToastContainer />
            <section className="finalize-project common-box-style">
                <div className="main-heading">
                    <h6>Declaration</h6>
                </div>
                <div className="bottom-content">
                    <div className="custom-checkbox">
                        <div className="form-group">
                            <input
                                type="checkbox"
                                id="first"
                                checked={confirmation}
                                onChange={() => {
                                    setConfirmation(!confirmation);
                                    setErrors({ ...errors, confirmation: '' });
                                }}
                            />
                            <label htmlFor="first"></label>
                        </div>
                        <label htmlFor="first">I confirm that all the information I provided is true.</label>

                    </div>
                    {errors.confirmation && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.confirmation}</p>}
                    <div className="custom-checkbox">
                        <div className="form-group">
                            <input
                                type="checkbox"
                                id="sec"
                                checked={understand}
                                onChange={() => {
                                    setUnderstand(!understand);
                                    setErrors({ ...errors, understand: '' });
                                }}
                            />
                            <label htmlFor="sec"></label>
                        </div>
                        <label htmlFor="sec">I understand that any false information may result in the rejection of my application or termination of the grant.</label>

                    </div>
                    {errors.understand && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.understand}</p>}
                    <div className="custom-checkbox">
                        <div className="form-group">
                            <input
                                type="checkbox"
                                id="third"
                                checked={agree}
                                onChange={() => {
                                    setAgree(!agree);
                                    setErrors({ ...errors, agree: '' });
                                }}
                            />
                            <label htmlFor="third"></label>
                        </div>
                        <label htmlFor="third">I agree to the <Link to="/grantprogramterms" target='_blank'>terms of use</Link> and the <Link to="/privacy" target='_blank'>privacy policy</Link>.</label>

                    </div>
                    {errors.agree && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.agree}</p>}
                </div>

                <div className="bottom-btns">
                    <button onClick={() => { setCheckForms(3) }} className="btn-backk">

                        <h6>Back</h6>
                    </button>
                    <button className="btn-blackk">
                        <h6>SUBMIT</h6>

                    </button>
                </div>
            </section>

            <Modal
                className="contact-modal applicationsubmit-modal"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Body>
                    <img
                        src="\assets\grant\application-submitted.svg"
                        alt="img"
                        className="img-fluid"
                    />
                    <h6>Application Submitted successfully</h6>
                    <p>Thank you for submitting your application! Your information has been received successfully.
                            <br /> <br />
                        You will receive a confirmation email shortly with details of your submission. If there are any further steps required our team will bee in touch.</p>
                        <a onClick={handleClose} className='btn-okay'>Okay</a>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Finalize;
