import React, { useEffect, useState } from 'react'
import "./applyforjob.scss"
import Navbar from '../landing/header/Navbar'
import { Link, useNavigate } from 'react-router-dom'
import Build from '../landing/Buildthefuture/Build'
import Footer from '../landing/footer/Footer'
import { Modal } from 'react-bootstrap'
const Applyforjob = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(() => {
        navigate("/");
    });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />
            <section className='applyjob'>
                <div className='custom-containermain'>
                    <div className='parentsapplyjob'>
                        <div className='left'>
                            <Link to="/careers">
                                <a>Back to All Jobs</a>
                            </Link>
                            <h2>Head of Marketing position at Mezada Technology</h2>
                            <p>We’re looking for Head of Marketing to join our team</p>
                            <div className='buttontwice'>
                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>100% Remote</button>
                                <button className='forwidth'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>Full time</button>
                            </div>
                            <div className='maintextparent'>
                                <div className='innertextmain'>
                                    <h2>About us:</h2>
                                    <p>At Mezada Technology, we firmly believe that the best way to predict the future is to invent it. We excel in providing cutting-edge tech solutions that meet customer needs and anticipate and adapt to evolving market trends. With a focus on innovation and customer satisfaction, our team delivers user-friendly platforms with customizable compensation structures. Whether it's UI/UX design, web development, mobile development, or blockchain solutions, we offer expertise, personalized solutions, and a commitment to quality assurance.</p>
                                </div>
                                <div className='innertextmain'>
                                    <h2>Job Description:</h2>
                                    <p>We're hiring a <span>Head of Communities</span> to enhance our projects’ visibility and engagement within various platforms and online communities, including Discord, Telegram, Reddit, etc., which are currently up and running with thousands of active users. This role is ideal for someone passionate about community engagement who can invent, strategize, and execute impactful and engaging content that resonates with the realm of Web3.</p>
                                </div>
                                <div className='innertextmain'>
                                    <h2>Responsibilities:</h2>
                                    <ul>
                                        <li>
                                            Supervise and communicate daily with community managers, brand ambassadors, and moderators - as if they were your direct subordinates.
                                        </li>
                                        <li>Generate frequent community sentiment reports with insights and action items for continuous improvement and growth.</li>
                                        <li>Monitor and manage crises, potential pain points, and emerging  FUDs, as well as spot potential collaboration and business opportunities.</li>
                                        <li>Develop and execute a community strategy to enhance our presence and engagement in both private and public communities.</li>
                                        <li>Create content that engages various communities/personas (where our audience is active)</li>
                                        <li>Respond to community managers and support representatives, navigate and forward user feedback and support tickets, providing cohesive, reassuring, and useful customer service.</li>
                                    </ul>
                                </div>
                                <div className='innertextmain'>
                                    <h2>Job requirements:</h2>
                                    <ul>
                                        <li>Experience in the world of cryptocurrency & blockchain technologies - a must.</li>
                                        <li>3+ years experience with community management.</li>
                                        <li>Proficiency in English - both written and spoken - at a native level.</li>
                                        <li>Technological and financial knowledge - preferably associated with crypto.</li>
                                        <li>Experience with team management & coordination - alongside independence and the ability to operate as a one-man operation.</li>
                                        <li>Ability to accommodate a demanding work schedule and flexible hours.</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='right'>
                            <div className='mainboxes'>

                                <h2>Apply for this job</h2>
                                <p>Please fill out the details below</p>
                                <div className='maininputssss'>
                                    <div className='innerinputs'>
                                        <p>Full Name</p>
                                        <input type='text' placeholder='Enter your name here...' />
                                    </div>
                                    <div className='innerinputs'>
                                        <p>Email</p>
                                        <input type='text' placeholder='Enter your email here...' />
                                    </div>
                                    <div className='innerinputs'>
                                        <p>Resume/CV</p>
                                        <div className='parentmaininput'>
                                            <div className='wrapper'>
                                                <input type='text' placeholder='(PDF, Word, TXT, 6MB Maximum)' />
                                                <label htmlFor='upload' className='uploadbtn'>

                                                    Upload
                                                </label>
                                                <input type='file' id='upload' placeholder='hhhhhhhhhh' className='d-none' />
                                            </div>
                                        </div>

                                    </div>
                                    <button onClick={() => setShow(true)} className='applybtn'>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Build />
            <Footer />



            <Modal
                className="contact-modal applicationsubmit-modal"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Body>
                    <img
                        src="\assets\grant\application-submitted.svg"
                        alt="img"
                        className="img-fluid"
                    />
                    <h6>Application Submitted successfully</h6>
                    <p>Thank you for submitting your application! Your information has been received successfully.
                            <br /> <br />
                        You will receive a confirmation email shortly with details of your submission. If there are any further steps required our team will bee in touch.</p>
                        <a onClick={handleClose} className='btn-okay'>Okay</a>
                </Modal.Body>
            </Modal>



        </>
    )
}

export default Applyforjob