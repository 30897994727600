import React from 'react'
import "./communityevents.scss"
import Navbar from '../landing/header/Navbar'
import Build from '../landing/Buildthefuture/Build'
import Footer from '../landing/footer/Footer'
const Communityevents = () => {
    return (
        <>
            <Navbar />
            <section className='Communityevents'>
                <div className='custom-container'>
                    <div className='parentcommunity'>
                        <div className='leftparentssss'>
                            <div className='maineventimg'>
                                <img src='\assets\frame12.svg' alt='img' className='imagess' />
                            </div>
                            <p className='grey'>Hosted By</p>
                            <h6 className='white'>Vion Foundation</h6>
                        </div>
                        <div className='right'>
                            <h2 className='mainhead'>Lorem ipsum odor amet, consectetuer adipiscing elit.</h2>
                            <div className='parentss'>
                                <div className='innerparentssss'>
                                    <div className='left'>
                                        <div className='innerleftimg'>
                                            <img src='\assets\Frame1.png' alt='img' className='img-fluid imagess' />
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <h6>Thursday, October 24</h6>
                                        <p>10:00 AM - Oct 26, 8:00 PM GMT+8</p>
                                    </div>
                                </div>
                                <div className='innerparentssss'>
                                    <div className='left'>
                                        <div className='innerleftimg'>
                                            <img src='\assets\Frame2.png' alt='img' className='img-fluid imagess' />
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <h6>PMQ</h6>
                                        <p>Hong Kong Island</p>
                                    </div>
                                </div>
                            </div>
                            <div className='registration'>
                                <p>Registration</p>
                            </div>
                            <div className='approval'>
                                <div className='mainimg'>
                                    <img src='\assets\Frame3.png' alt='img' className='img-fluid inner' />
                                </div>
                                <div className='content'>
                                    <h6>Approval Required</h6>
                                    <p>Your registration is subject to approval by the host.</p>
                                </div>
                            </div>
                            <div className='eventsss'>
                                <h3>About Event</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>
                                <p>Sed viverra adipiscing feugiat velit parturient phasellus sollicitudin ullamcorper? Facilisis eros nostra dis laoreet orci. Est suscipit turpis id bibendum hendrerit maximus diam potenti. Augue facilisis penatibus imperdiet ornare interdum. Donec eleifend at curae risus risus imperdiet! Dolor consectetur luctus ante eget nascetur fusce magna duis risus. Facilisis ipsum dis platea hendrerit massa molestie bibendum rhoncus.</p>
                            </div>
                            <div className='lastpara'>
                                <h3>During the event, you can expect:</h3>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minims.</li>
                                    <li>Lorem molestie sapien montes molestie, laoreet viverra. Cursus purus eros senectus velit habitasse taciti.</li>
                                    <li>Eros vehicula ex sagittis donec risus tellus imperdiet.</li>
                                    <li>Etiam integer praesent cubilia consectetur hac urna. Augue turpis molestie dis taciti non.</li>
                                    <li>Feugiat semper aenean integer curabitur dolor potenti.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Build />
            <Footer />
        </>
    )
}

export default Communityevents