import React, { useEffect, useRef } from 'react'
import "./roadmap.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Roadmap = () => {
    const textRefslogo = useRef([]);

    useEffect(() => {
        textRefslogo.current.forEach((element) => {
            gsap.fromTo(
                element,
                { rotationX: 90, opacity: 0 },
                {
                    rotationX: 0,
                    opacity: 1,
                    duration: 2,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                    },
                }
            );
        });
    }, []);

    const textRefs = useRef([]);

    useEffect(() => {
        textRefs.current.forEach((text) => {
            gsap.fromTo(
                text,
                { y: '100%' },
                {
                    y: '0%',
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: text,
                        start: 'top 80%',
                        end: 'top 20%',
                        once: true
                    },
                }
            );
        });
    }, []);

    const imageRefs = useRef([]);

    useEffect(() => {
        imageRefs.current.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 2.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                    },
                }
            );
        });
    }, []);
    return (
        <>
            <section className="our-partners roadmap-section">
                <img ref={(el) => (imageRefs.current[0] = el)} src="\assets\roadmap\roadmap-bg.png" alt="img" className='img-fluid partners-bg' />
                <img ref={(el) => (imageRefs.current[1] = el)} src="\assets\partners\partners-bg-mobile.png" alt="img" className='img-fluid partners-bg d-none partners-bg-mobile' />
                <div className="custom-container">
                    <div className="main-heading pb-4">
                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                            <h4 style={{
                                margin: 0,
                                transform: 'translateY(120%)',
                            }} ref={(el) => (textRefs.current[0] = el)}>Roadmap </h4>
                        </div>
                        {/* <div style={{ overflow: 'hidden', position: 'relative' }}>
                            <h4 style={{
                                margin: 0,
                                transform: 'translateY(120%)',
                            }} ref={(el) => (textRefs.current[1] = el)}>Galaxies</h4>
                        </div> */}
                    </div>
                    <div className="bottom-roadmap">
                        <div className="left-heading">
                            <div className="first-heading common-heading-style style1">
                                <p>PHASE 1</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[2] = el)}>Q4 2024</h4>
                                </div>
                            </div>
                            <div className="sec-heading common-heading-style style2">
                                <p>PHASE 2</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[3] = el)}>Q1 - 2025</h4>
                                </div>
                            </div>
                            <div className="third-heading common-heading-style style3">
                                <p>PHASE 3</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[4] = el)}>Q2 - 2025</h4>
                                </div>
                            </div>
                            <div className="sec-heading common-heading-style style4">
                                <p>PHASE 4</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[5] = el)}>Q3 - 2025</h4>
                                </div>
                            </div>
                            <div className="sec-heading common-heading-style style5">
                                <p>PHASE 5</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[6] = el)}>Q4 - 2025</h4>
                                </div>
                            </div>
                            <div className="sec-heading common-heading-style style6">
                                <p>PHASE 6</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[7] = el)}>Q1 - 2026</h4>
                                </div>
                            </div>
                            <div className="sec-heading common-heading-style  style7">
                                <p>PHASE 7</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[8] = el)}>Q2 - 2026</h4>
                                </div>
                            </div>

                        </div>
                        <div className="middle-line">
                            <img src="\assets\roadmap\line-img.png" alt="img" className='img-fluid' />
                        </div>
                        <div className="right-detail">
                            <div className="single-detail phase1">
                                <ul className=''>
                                    <li className=''>
                                        Whitepaper
                                    </li>
                                    <li className=''>
                                        Website
                                    </li>
                                    <li className=''>
                                        Testnet  V 1.0
                                    </li>
                                    <li className=''>
                                        Faucet Website
                                    </li>
                                    <li className=''>
                                        Technical Documentation
                                    </li>
                                    <li className=''>
                                        Beacon & Public Explorer
                                    </li>
                                    <li className=''>
                                        Genesis Stake Sale
                                    </li>
                                </ul>

                            </div>
                            <div className="single-detail phase2">
                                <ul className=''>
                                    <li className=''>
                                        Testnet V 1.1
                                    </li>
                                    <li className=''>
                                        Validator Setup V 1.0
                                    </li>
                                    <li className=''>
                                        VionRewards Hub V 1.0
                                    </li>
                                    <li className=''>
                                        Token launcher V 1.0
                                    </li>
                                    <li className=''>
                                        Seed & Private Sale
                                    </li>

                                </ul>
                            </div>
                            <div className="single-detail phase3">
                                <ul className=''>
                                    <li className=''>
                                        Testnet DEX
                                    </li>
                                    <li className=''>
                                        FLUID Staking (Flexible Liquid Staking)
                                    </li>
                                    <li className=''>
                                        Token/NFT launcher V 1.1
                                    </li>
                                    <li className=''>
                                        VionRewards Hub V 1.1
                                    </li>
                                    <li className=''>
                                        Beacon/Public Explorer V 1.1
                                    </li>
                                    <li className=''>
                                        Seed/Private and Strategic Sale
                                    </li>

                                </ul>
                            </div>
                            <div className="single-detail phase4">
                                <ul className=''>
                                    <li className=''>
                                        Mainnet Live
                                    </li>
                                    <li className=''>
                                        Snapshot Testnet Activity
                                    </li>
                                    <li className=''>
                                        Claims of Tokens
                                    </li>
                                    <li className=''>
                                        Listing on CEXs
                                    </li>
                                    <li className=''>
                                        Claim at Testnet Rewards
                                    </li>
                                    <li className=''>
                                        Beacon & Public Explorer Mainnet
                                    </li>
                                    <li className=''>
                                        Token Launcher and DEX
                                    </li>
                                    <li className=''>
                                        Validator Setup
                                    </li>

                                </ul>
                            </div>
                            <div className="single-detail phase5">
                                <ul className=''>
                                    <li className=''>
                                        FLUID Staking (Flexible Liquid Staking)
                                    </li>
                                    <li className=''>
                                        Onramp/Offramp Solution
                                    </li>
                                    <li className=''>
                                        Governance Module
                                    </li>
                                    <li className=''>
                                        Vion CEX V 1.0
                                    </li>
                                    <li className=''>
                                        VionRewards Hub Mainnet
                                    </li>
                                    <li className=''>
                                        Token Launcher V 1.2 ( locks, escrow, token, NFT)
                                    </li>


                                </ul>
                            </div>
                            <div className="single-detail phase6">
                                <ul className=''>
                                    <li className=''>
                                        Vion CEX V 1.1
                                    </li>
                                    <li className=''>
                                        Perpetual in CEX V 1.0
                                    </li>
                                    <li className=''>
                                        Native Launchpad
                                    </li>
                                    <li className=''>
                                        NFT Marketplace
                                    </li>
                                    <li className=''>
                                        Pay Masters & Account Abstraction
                                    </li>


                                </ul>
                            </div>
                            <div className="single-detail phase7">
                                <ul className=''>
                                    <li className=''>
                                        Mobile Nodes Validator
                                    </li>
                                    <li className=''>
                                        CEX & Perpetual V 2.0 ( KYC & Chainalysis )
                                    </li>
                                    <li className=''>
                                        Multichain NFT Marketplace
                                    </li>
                                    <li className=''>
                                        Mobile App for CEX ( ios/android )
                                    </li>
                                    <li className=''>
                                        Onramp/ Offramp V 2.0
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-roadmap-mobile d-none">
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 1</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[66] = el)}>Q4 - 2024</h4>
                                </div>
                                <ul className=''>
                                    <li className=''>
                                        Whitepaper
                                    </li>
                                    <li className=''>
                                        Website
                                    </li>
                                    <li className=''>
                                        Testnet  V 1.0
                                    </li>
                                    <li className=''>
                                        Faucet Website
                                    </li>
                                    <li className=''>
                                        Technical Documentation
                                    </li>
                                    <li className=''>
                                        Beacon & Public Explorer
                                    </li>
                                    <li className=''>
                                        Genesis Stake Sale
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 2</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[77] = el)}>Q1 - 2025</h4>
                                </div>
                                <ul className=''>
                                    <li className=''>
                                        Testnet V 1.1
                                    </li>
                                    <li className=''>
                                        Validator Setup V 1.0
                                    </li>
                                    <li className=''>
                                        VionRewards Hub V 1.0
                                    </li>
                                    <li className=''>
                                        Token launcher V 1.0
                                    </li>
                                    <li className=''>
                                        Seed & Private Sale
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 3</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[88] = el)}>Q2 - 2025</h4>
                                </div>
                                <ul className=''>
                                    <li className=''>
                                        Testnet DEX
                                    </li>
                                    <li className=''>
                                        FLUID Staking (Flexible Liquid Staking)
                                    </li>
                                    <li className=''>
                                        Token/NFT launcher V 1.1
                                    </li>
                                    <li className=''>
                                        VionRewards Hub V 1.1
                                    </li>
                                    <li className=''>
                                        Beacon/Public Explorer V 1.1
                                    </li>
                                    <li className=''>
                                        Seed/Private and Strategic Sale
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 4</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[99] = el)}>Q3 - 2025</h4>
                                </div>
                                <ul className=''>
                                    <li className=''>
                                        Mainnet Live
                                    </li>
                                    <li className=''>
                                        Snapshot Testnet Activity
                                    </li>
                                    <li className=''>
                                        Claims of Tokens
                                    </li>
                                    <li className=''>
                                        Listing on CEXs
                                    </li>
                                    <li className=''>
                                        Claim at Testnet Rewards
                                    </li>
                                    <li className=''>
                                        Beacon & Public Explorer Mainnet
                                    </li>
                                    <li className=''>
                                        Token Launcher and DEX
                                    </li>
                                    <li className=''>
                                        Validator Setup
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 5</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[100] = el)}>Q4 - 2025</h4>
                                </div>
                                <ul className=''>
                                    <li className=''>
                                        FLUID Staking (Flexible Liquid Staking)
                                    </li>
                                    <li className=''>
                                        Onramp/Offramp Solution
                                    </li>
                                    <li className=''>
                                        Governance Module
                                    </li>
                                    <li className=''>
                                        Vion CEX V 1.0
                                    </li>
                                    <li className=''>
                                        VionRewards Hub Mainnet
                                    </li>
                                    <li className=''>
                                        Token Launcher V 1.2 ( locks, escrow, token, NFT)
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 6</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[111] = el)}>Q1 - 2026</h4>
                                </div>
                                <ul className=''>
                                    <li className=''>
                                        Vion CEX V 1.1
                                    </li>
                                    <li className=''>
                                        Perpetual in CEX V 1.0
                                    </li>
                                    <li className=''>
                                        Native Launchpad
                                    </li>
                                    <li className=''>
                                        NFT Marketplace
                                    </li>
                                    <li className=''>
                                        Pay Masters & Account Abstraction
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 7</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[122] = el)}>Q2 - 2026</h4>
                                </div>
                                <ul className=''>
                                    <li className=''>
                                        Mobile Nodes Validator
                                    </li>
                                    <li className=''>
                                        CEX & Perpetual V 2.0 ( KYC & Chainalysis )
                                    </li>
                                    <li className=''>
                                        Multichain NFT Marketplace
                                    </li>
                                    <li className=''>
                                        Mobile App for CEX ( ios/android )
                                    </li>
                                    <li className=''>
                                        Onramp/ Offramp V 2.0
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Roadmap
