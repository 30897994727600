import React, { useEffect, useRef } from 'react'
import "./partners.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Partners = () => {
    const textRefslogo = useRef([]);

    useEffect(() => {
        textRefslogo.current.forEach((element) => {
            gsap.fromTo(
                element,
                { rotationX: 90, opacity: 0 },
                {
                    rotationX: 0,
                    opacity: 1,
                    duration: 2,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                    },
                }
            );
        });
    }, []);

    const textRefs = useRef([]);

    useEffect(() => {
        textRefs.current.forEach((text) => {
            gsap.fromTo(
                text,
                { y: '100%' },
                {
                    y: '0%',
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: text,
                        start: 'top 80%',
                        end: 'top 20%',
                        once: true
                    },
                }
            );
        });
    }, []);

    const imageRefs = useRef([]);

    useEffect(() => {
        imageRefs.current.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 2.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                    },
                }
            );
        });
    }, []);
    return (
        <>
            <section className="our-partners">
                <img ref={(el) => (imageRefs.current[0] = el)} src="\assets\partners\partners-bg.png" alt="img" className='img-fluid partners-bg' />
                <img ref={(el) => (imageRefs.current[1] = el)} src="\assets\partners\partners-bg-mobile.png" alt="img" className='img-fluid partners-bg d-none partners-bg-mobile' />
                <div className="custom-container">
                    <div className="main-heading">
                        <p>Our Partners</p>
                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                            <h4 style={{
                                margin: 0,
                                transform: 'translateY(120%)',
                            }} ref={(el) => (textRefs.current[0] = el)}>We work with best</h4>
                        </div>
                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                            <h4 style={{
                                margin: 0,
                                transform: 'translateY(120%)',
                            }} ref={(el) => (textRefs.current[1] = el)}>partners</h4>
                        </div>
                    </div>
                    <div className="bottom-content">
                        <div className="parent-logo">
                            <div ref={(el) => (textRefslogo.current[1] = el)} className="single-logo">
                                <img src="\assets\partners\1.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[2] = el)} className="single-logo">
                                <img src="\assets\partners\2.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[3] = el)} className="single-logo">
                                <img src="\assets\partners\3.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[4] = el)} className="single-logo">
                                <img src="\assets\partners\4.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[5] = el)} className="single-logo">
                                <img src="\assets\partners\5.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[6] = el)} className="single-logo">
                                <img src="\assets\partners\6.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[7] = el)} className="single-logo">
                                <img src="\assets\partners\7.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[8] = el)} className="single-logo">
                                <img src="\assets\partners\8.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[9] = el)} className="single-logo">
                                <img src="\assets\partners\9.svg" alt="img" className='img-fluid' />
                            </div>
                            <div ref={(el) => (textRefslogo.current[10] = el)} className="single-logo">
                                <img src="\assets\partners\10.svg" alt="img" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Partners
