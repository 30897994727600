import React from 'react'
import Navbar from '../landing/header/Navbar'
import Build from '../landing/Buildthefuture/Build'
import "./health.scss";
import Footer from '../landing/footer/Footer';

const Health = () => {
    return (
        <>
            <Navbar />
            <section className="health-section">
                <div className="custom-container">
                    <div className='inner_pro'>
                        <h1>Health / Status</h1>
                        <img src='\assets\planets.png' className='planets' />
                    </div>
                    <div className='innnernew'>
                        <h5>All Systems Operational</h5>
                        <div className='outttter'>
                            <div className='cardpart'>
                                <div className='cadrdddds'>
                                    <p>Mainnet Beta - Cluster</p>
                                    <h6>Operational</h6>
                                </div>
                                <img src='\assets\line.png' className='img-fluid' />
                                <div className='innnner_div'>
                                    <h1>90 days ago</h1>
                                    <h2></h2>
                                    <h1>100.0 % uptime</h1>
                                    <h2></h2>
                                    <h1>Today</h1>
                                </div>
                            </div>
                            <div className='cardpart'>
                                <div className='cadrdddds'>
                                    <div className='cadrdddds'>
                                        <p>Mainnet Beta - RPC Nodes</p>

                                    </div>
                                    <h6>Operational</h6>
                                </div>

                                <img src='\assets\line.png' className='img-fluid' />
                                <div className='innnner_div'>
                                <h1>90 days ago</h1>
                                    <h2></h2>
                                    <h1>100.0 % uptime</h1>
                                    <h2></h2>
                                    <h1>Today</h1>
                                </div>
                            </div>
                            <div className='cardpart'>
                                <div className='cadrdddds'>
                                    <p>Explorer</p>
                                    <h6>Operational</h6>
                                </div>
                                <img src='\assets\line.png' className='img-fluid' />
                                <div className='innnner_div'>
                                <h1>90 days ago</h1>
                                    <h2></h2>
                                    <h1>100.0 % uptime</h1>
                                    <h2></h2>
                                    <h1>Today</h1>
                                </div>
                            </div>
                            <div className='cardpart'>
                                <div className='cadrdddds'>
                                    <p>vion.com</p>
                                    <h6>Operational</h6>
                                </div>
                                <img src='\assets\line.png' className='img-fluid' />
                                <div className='innnner_div'>
                                <h1>90 days ago</h1>
                                    <h2></h2>
                                    <h1>100.0 % uptime</h1>
                                    <h2></h2>
                                    <h1>Today</h1>
                                </div>
                            </div>
                            <div className='cardpart'>
                                <div className='cadrdddds'>
                                    <p>Break Vion</p>
                                    <h6>Operational</h6>
                                </div>
                                <img src='\assets\line.png' className='img-fluid' />
                                <div className='innnner_div'>
                                <h1>90 days ago</h1>
                                    <h2></h2>
                                    <h1>100.0 % uptime</h1>
                                    <h2></h2>
                                    <h1>Today</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Build />
            <Footer />
        </>
    )
}

export default Health