import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Sidebar = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="sidebar">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className='p-0'>
                        <Accordion.Header>Get Started</Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item as={NavLink} to="/docs" eventKey="0" className='p-0 inneraccordion'>
                                    <Accordion.Header>Quick Start</Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/dddd"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                       Reading from Network
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/faqs"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                     Writing to Network
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/domaindeployment"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                          Deploying Program
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <NavLink
                                exact
                                to="/docsdd"
                                className={({ isActive }) => (isActive ? "active-link" : "")}
                            >
                              Installation
                            </NavLink>
                            <NavLink
                                exact
                                to="/faqs"
                                className={({ isActive }) => (isActive ? "active-link" : "")}
                            >
                           Intro to Development
                            </NavLink>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="mobile-sidebar d-none">
                {/* <a href="/"> <img src="\logo.svg" alt="img" className='img-fluid' /></a> */}
                <a onClick={handleShow}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3 17H21M3 12H21M3 7H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg></a>
            </div>


            <Offcanvas className="mobile-offcanvas" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <img src="\logo.svg" alt="img" className='img-fluid' />
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className='p-0'>
                        <Accordion.Header>Get Started</Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item as={NavLink} to="/docs" eventKey="0" className='p-0 inneraccordion'>
                                    <Accordion.Header>Quick Start</Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/dddd"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                       Reading from Network
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/faqs"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                     Writing to Network
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/domaindeployment"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                          Deploying Program
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <NavLink
                                exact
                                to="/docsdd"
                                className={({ isActive }) => (isActive ? "active-link" : "")}
                            >
                              Installation
                            </NavLink>
                            <NavLink
                                exact
                                to="/faqs"
                                className={({ isActive }) => (isActive ? "active-link" : "")}
                            >
                           Intro to Development
                            </NavLink>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Sidebar;
