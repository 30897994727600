import React from 'react'
import Navbar from '../landing/header/Navbar'
import Build from '../landing/Buildthefuture/Build'
import "./providers.scss";

import Footer from '../landing/footer/Footer'

const Providers = () => {
    return (
        <>

            <Navbar />
            <section className="providers-section">
                <div className="custom-container">
                    <div className='inner_pro'>
                        <h1>RPC Providers</h1>
                        <img src='\assets\planets.png' className='planets' />
                    </div>
                    <div className='innner_new'>
                        <p>Lorem ipsum odor amet, consectetuer adipiscing elit. Fringilla magnis dictum sem fermentum facilisi per nascetur diam! Feugiat praesent condimentum gravida quam iaculis sodales velit. Dignissim odio accumsan interdum lorem vulputate ante; habitant tortor. Lobortis quis auctor nam; magnis sodales torquent. Nostra at augue netus placerat molestie.</p>
                   <p>Efficitur amet fusce platea accumsan mattis curae? Nec nec vehicula sodales posuere porttitor molestie. Massa volutpat congue ut fermentum commodo feugiat.<span>Elit tellus felis</span> malesuada mi tincidunt facilisis vulputate. Id diam sed integer urna platea ac. Vulputate fusce eros mi nulla ultricies volutpat, laoreet sit. Volutpat torquent interdum dis nisi fames velit nascetur orci. Odio venenatis pellentesque blandit praesent efficitur blandit euismod. Faucibus accumsan nec taciti vehicula nullam.</p>
                   <p>At praesent consectetur elit etiam quam vitae. Morbi litora dictum iaculis enim lectus pretium. Dis hendrerit cras platea facilisis a rutrum. Tristique ligula vivamus habitasse imperdiet fringilla dapibus. Aliquet cursus convallis nisi rhoncus tortor neque. Placerat praesent maecenas; primis et eu tincidunt mattis. Aenean dis luctus magnis; potenti augue amet velit. Sed viverra adipiscing feugiat velit parturient phasellus sollicitudin ullamcorper? Facilisis eros nostra dis laoreet orci. Est suscipit turpis id bibendum hendrerit maximus diam potenti. Augue facilisis penatibus imperdiet ornare interdum. Donec eleifend at curae risus risus imperdiet! Dolor consectetur luctus ante eget nascetur fusce magna duis risus. Facilisis ipsum dis platea hendrerit massa molestie bibendum rhoncus.</p>
                   <p>Justo molestie orci habitant vehicula sodales phasellus.<span>Scelerisque ridiculus </span> fusce nisi in platea. Sem conubia arcu eleifend aptent adipiscing. Porttitor erat ornare maximus praesent lacus curabitur penatibus ante sapien. Et integer orci fusce pellentesque proin vitae natoque. Lobortis praesent rutrum praesent elementum arcu. Interdum nisi curabitur augue sit nam sapien eros ac pulvinar?</p>
                   <p>Posuere condimentum sodales cras vulputate fermentum condimentum eu. Ex luctus praesent vestibulum; vulputate eu maecenas! Elit nostra dictum orci amet erat faucibus dictum vitae. Posuere aliquam ut conubia laoreet sit.<span>Lacinia mattis</span>  hac viverra dui adipiscing nisl. Lacinia cursus auctor vulputate viverra magna aenean hac. Quis netus in varius leo enim. Facilisi quis ex aptent eget malesuada tempus ultrices augue dui.</p>
                   <h1>Some free RPC providers:</h1>
                   <h1>Testnet</h1>
                   <li>https://api.testnet.vion.com</li>
                   <h1 className='devneeet'>Devnet</h1>
                   <li>https://api.devnet.vion.com
                   </li>
                   <li>https://rpc.ankr.com/vion_devnet
                   </li>
                   <li>Helius
                   </li>
                   <li>QuickNode</li>
                   <h1  className='devneeet'>Mainnet-beta</h1>
                   <li>https://api.mainnet-beta.vion.com</li>
                   <li>Syndica </li>
                   <li>Blockdaemon </li>
                   <li>https://rpc.ankr.com/vion</li>
                   <li>GetBlock</li>
                   <li>Helius</li>
                   <li>QuickNode</li>
                   <h1  className='devneeet mydivvvv'>Private Services</h1>
                   <p className='paraaagraphhh'>Lorem ipsum odor amet, consectetuer adipiscing elit. Fringilla magnis dictum sem fermentum facilisi per nascetur diam! Feugiat praesent condimentum gravida quam iaculis sodales velit. Dignissim odio accumsan interdum lorem vulputate ante; habitant tortor. Lobortis quis auctor nam; magnis sodales torquent. Nostra at augue netus placerat molestie.</p>
                    <li>QuickNode<span>QuickNode</span></li>
                    <li>Triton/RPC Poo<span>Triton/RPC Poo</span></li>
                    <li>Chainflow
                    <span> (dedicated)</span></li>
                    <li>Chainstack<span>(shared and dedicated)
                    </span></li>
                    <li>GenesysGo<span>(dedicated)
                    </span></li>
                    <li>Figment<span> (shared)
                    </span></li>
                    <li>Foundation Server Program<span>- bare metal at a discount to run-your-own
                    </span></li>
                    <li>Syndica
                    </li>
                    <li>Alchemy<span> (shared)
                    </span></li>
                    <li>Blockdaemon</li>
                    <li>Ankr</li>
                    <li>GetBlock</li>
                    <li>Helius</li>
                    <li>NOWNodes<span>(shared and dedicated)</span></li>
                    
                    
                    </div>
                </div>
            </section>
            <Build />
            <Footer />
        </>
    )
}

export default Providers