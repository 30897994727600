import React from 'react'
import "./tempor.scss";

const Tempor = () => {
    return (
        <>
            <section className="tempor-section">
            <img src='\assets\eliptwo.png' className='imgelip2 img-fluid' />
                <div className="custom-container">
                    <div className='part2'>
                        <div className='right_side'>
                          
                            <img src='\assets\part1img.png' className=' img-fluid' />
                        </div>
                        <div className='left_side'>
                            <h3>Our thesis</h3>
                            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h2>

                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam. </p>
                            <p>Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.</p>

                            <button>Learn More<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <g clip-path="url(#clip0_1303_29)">
                                    <path d="M8.16663 14H19.8333M19.8333 14L15.1666 9.33333M19.8333 14L15.1666 18.6667" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1303_29">
                                        <rect width="28" height="28" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg></button>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Tempor