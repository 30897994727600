import React from 'react'
import "./resourcehub.scss"
import Navbar from '../landing/header/Navbar'
import Build from '../landing/Buildthefuture/Build'
import Footer from '../landing/footer/Footer'
const Resourcehub = () => {
    return (
        <>
            <Navbar />
            <section className='resource'>
                <div className='custom-container'>
                    <h1 className='mainhead'><span>VION</span> Resource Hub</h1>
                </div>
            </section>
            <section className='find'>
                <div className='custom-container'>
                    <div className='mainheading'>
                        <h3><span>Way</span>finding</h3>
                        <p>The following are our most frequented and updated communication channels. You name it, we’ve got it!</p>
                    </div>
                    <div className='parentcardss'>
                        <div className='innercard'>
                            <img src='\assets\shade1.png' alt='img' className='shade' />
                            <div className='innercontentss'>
                                <h2 className='upperhead'>Announcements</h2>
                                <div className='mainimg'>
                                    <img src='\assets\telegram.png' alt='img' className='img-fluid innerimages' />
                                </div>
                                <h5>Telegram</h5>
                                <p>85,032 Members</p>

                            </div>
                        </div>
                        <div className='innercard'>
                            <img src='\assets\shade1.png' alt='img' className='shade' />
                            <div className='innercontentss'>
                                <h2 className='upperhead'>Announcements</h2>
                                <div className='mainimg'>
                                <img src='\assets\twitter.png' alt='img' className='img-fluid innerimages' />
                                </div>
                                <h5>Twitter</h5>
                                <p>85,032 Members</p>

                            </div>
                        </div>
                        <div className='innercard'>
                            <img src='\assets\shade1.png' alt='img' className='shade' />
                            <div className='innercontentss'>
                                <h2 className='upperhead'>Announcements</h2>
                                <div className='mainimg'>
                                    <img src='\assets\twitter.png' alt='img' className='img-fluid innerimages' />
                                </div>
                                <h5>Github</h5>
                                <p>85,032 Members</p>

                            </div>
                        </div>
                        <div className='innercard'>
                            <img src='\assets\shade1.png' alt='img' className='shade' />
                            <div className='innercontentss'>
                                <h2 className='upperhead'>Announcements</h2>
                                <div className='mainimg'>
                                    <img src='\assets\reddit.png' alt='img' className='img-fluid innerimages' />
                                </div>
                                <h5>Reddit</h5>
                                <p>85,032 Members</p>

                            </div>
                        </div>
                        <div className='innercard'>
                            <img src='\assets\shade1.png' alt='img' className='shade' />
                            <div className='innercontentss'>
                                <h2 className='upperhead'>Announcements</h2>
                                <div className='mainimg'>
                                    <img src='\assets\discord.png' alt='img' className='img-fluid innerimages' />
                                </div>
                                <h5>Discord</h5>
                                <p>85,032 Members</p>

                            </div>
                        </div>
                        <div className='innercard'>
                            <img src='\assets\shade1.png' alt='img' className='shade' />
                            <div className='innercontentss'>
                                <h2 className='upperhead'>Announcements</h2>
                                <div className='mainimg'>
                                    <img src='\assets\youtube.png' alt='img' className='img-fluid innerimages' />
                                </div>
                                <h5>Youtube</h5>
                                <p>85,032 Members</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='videos'>
                <div className='custom-container'>
                    <div className='upperheadingvideo'>
                        <div className='left'>
                            <h2><span>Featured</span> Videos</h2>
                        </div>
                        <div className='right'>
                            <div className='twisebutton'>
                                <button> <img src='\assets\Arrow.png' alt='img' className='img-fluid'/>  Youtube</button>
                                <button> <img src='\assets\Arrow.png' alt='img' className='img-fluid'/>Podcast</button>
                            </div>
                        </div>
                    </div>
                    <div className='parentsssss'>
                        <div className='left'>
                            <div className='daoimg'>
                                <img src='\assets\Dao.png' alt='img' className='img-fluid innerdao'/>
                            </div>
                            <h2>What are you building? Ft. Deanslist DAO</h2>
                            <div className='watchbutton'>
                                <button>Watch</button>
                            </div>
                        </div>
                        <div className='rig'>
                            <div className='textttttttttttttttt'>
                                <div className='innerttext'>
                                    <p>Solana Changelog Oct 2 - Radar, Partitioned Rent, and Simulating Compute in CLI</p>
                                    <button>Watch</button>
                                </div>
                                <div className='brdr'></div>
                                <div className='innerttext'>
                                    <p>The Placeholder Playbook w/ Chris Burniske and Joel Monegro.</p>
                                    <button>Watch</button>
                                </div>
                                <div className='brdr'></div>
                                <div className='innerttext'>
                                    <p>What Are You Building? Ft. MagicBlock</p>
                                    <button>Watch</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Build />
            <Footer />
        </>
    )
}

export default Resourcehub