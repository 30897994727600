import React, { useEffect, useRef, useState } from 'react'
import "./ourthesis.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import axios from 'axios';
import Environment from '../../../utils/Environment';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const OurThesis = () => {
    const textRefs = useRef([]);

    useEffect(() => {
        textRefs.current.forEach((text) => {
            gsap.fromTo(
                text,
                { y: '120%' },
                {
                    y: '0%',
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: text,
                        start: 'top 80%',
                        end: 'top 20%',
                        once: true
                    },
                }
            );
        });
    }, []);


    const imageRefs = useRef([]);

    useEffect(() => {
        imageRefs.current.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 2,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                    },
                }
            );
        });
    }, []);


    const [posts, setPosts] = useState([]);

    console.log(posts, "aasdfgh thesis thesis");

    const getAllPostsByCat = async () => {
        await axios.get(Environment.backendUrl + `wp/v2/posts?categories=3`)
            .then((response) => {
                console.log("🚀 ~ .then ~ getAllPostsByCat: video okokok", response.data);
                setPosts(response?.data[0])
            }).catch((error) => {
                console.log(error, 'getAllPostsByCat error');
            })
    }

    useEffect(() => {
        getAllPostsByCat();
    }, []);

    return (
        <>
            <section className="our-thesis">
                <img src="\assets\ourthesis\thesis-bg.png" alt="img" className='img-fluid thesis-bg' />
                <img ref={(el) => (imageRefs.current[0] = el)} src="\assets\ourthesis\topshadow-blue.png" alt="img" className='img-fluid topshadow-blue' />
                <img ref={(el) => (imageRefs.current[1] = el)} src="\assets\ourthesis\topshadow-bluemobile.png" alt="img" className='img-fluid topshadow-blue topshadow-bluemobile d-none' />
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-content">
                            <div className="main-heading">
                                <p>Our thesis</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[0] = el)}>{posts?.title?.rendered}</h4>
                                </div>
                                {/* <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[1] = el)}>will reshape financial</h4>
                                </div>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[2] = el)}>services in the way that</h4>
                                </div>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[3] = el)}>the Internet reshaped</h4>
                                </div>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[4] = el)}>media.</h4>
                                </div> */}
                            </div>

                            {/* <p className='para' style={{ marginTop: "49px" }}>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore. Sit facere veniam qui dolores autem aut nihil laborum!</p>
                            <p className='para'>Vel molestiae ipsa est animi repudiandae et fugit quisquam. Ut rerum velit ab odio aliquid in perferendis consequatur a possimus consequatur qui mollitia saepe ut nostrum adipisci.</p>
                             */}

                            <div className="para bottom-content wordpress-content" dangerouslySetInnerHTML={{ __html: posts ? posts?.excerpt?.rendered : null }}></div>

                            <Link to={`/blogdetail/${posts?.slug}`} className='white-btn hover-button'>
                                <span className="button-text">Learn More</span>
                                <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <g clip-path="url(#clip0_261_9)">
                                        <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33333M19.8334 14L15.1667 18.6667" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_261_9">
                                            <rect width="28" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg></span>
                                <span className="new-button-text" style={{ marginBottom: "12px" }}>Learn More</span>
                                <span className="new-arrow" style={{ right: "14px" }}><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <g clip-path="url(#clip0_261_9)">
                                        <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33333M19.8334 14L15.1667 18.6667" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_261_9">
                                            <rect width="28" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg></span>
                            </Link>
                        </div>
                        <div className="right-img">
                            <div className="img-wrapper">
                                <img src="\assets\ourthesis\globe-img.png" alt="img" className='img-fluid' />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default OurThesis
