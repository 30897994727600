import React, { useEffect } from 'react'
import "./contactus.scss"
import Navbar from '../landing/header/Navbar'
import Build from '../landing/Buildthefuture/Build'
import Footer from '../landing/footer/Footer'
const Contactus = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />
            <section className='maincareer'>
                <div className='custom-container'><h1 className='mainhead'>Contact Us</h1></div>

            </section>
            <section className='innertextttt'>
                <div className='custom-container'>
                    <div className='innertextparent'>
                        <div className='twiceinput'>
                            <div className='left'>
                                <p>First Name</p>
                                <input type='text' placeholder='Enter First Name' />
                            </div>
                            <div className='left'>
                                <p>Last Name</p>
                                <input type='text' placeholder='Enter Last Name' />
                            </div>

                        </div>
                        <div className='inputsssss'>
                            <p>Email</p>
                            <input type='text' placeholder='Enter Email' />
                        </div>
                        <div className='texttttt'>
                            <p>Message</p>
                            <textarea type="text" placeholder='Enter Message' />
                        </div>
                        <button className='getin'>Get in Touch <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
                            <path d="M5 14H24M24 14L16.4 6M24 14L16.4 22" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></button>
                    </div>
                </div>

            </section>
            <Build />
            <Footer />
        </>
    )
}

export default Contactus