import React from 'react'
import "./grant.scss"
import Navbar from '../landing/header/Navbar'
import Build from '../landing/Buildthefuture/Build'
import Footer from '../landing/footer/Footer'
import { Link } from 'react-router-dom'

const Grant = () => {
    return (
        <>
            <Navbar />
            <section className="vion-grant">
                <div className="top-head">
                    <img src="\assets\grant\head-bg.png" alt="img" className='img-fluid head-bg' />
                    <div className="custom-container">
                        <h6><span>VION</span> Grants</h6>
                    </div>
                </div>
                <div className="first-section">
                    <img src="\assets\grant\firstsectionbg.png" alt="img" className='img-fluid firstsectionbg' />
                    <div className="custom-container">
                        <div className="parent">
                            <div className="left-side">
                                <div className="main-content">
                                    <h6><span>VION</span> Grants</h6>
                                    <p>The <span>VION</span> Grant Program has a total budget of $5,000,000 worth of <span>VION</span>, which will be allocated to fund a diverse range of projects across various stages of development. Our goal is to empower creators, entrepreneurs, and innovators who share our vision of enabling individuals to truly own and control their personal data.
                                        <br /><br />
                                        We welcome proposals from individuals, teams, and organizations worldwide, regardless of their size or experience. Whether you are a seasoned developer, a passionate hobbyist, or a visionary entrepreneur, we encourage you to apply and share your ideas with us.
                                    </p>
                                    <Link to="/applyforvion" className='btn-apply'>Apply <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M8.16663 13.9997H19.8333M19.8333 13.9997L15.1666 9.33301M19.8333 13.9997L15.1666 18.6663" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></Link>
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="wrapper-img">
                                    <img src="\assets\grant\firstsecmain.png" alt="img" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sec-section">
                    <img src="\assets\grant\feature-bg.png" alt="img" className='img-fluid feature-bg' />
                    <div className="custom-container">
                        <div className="main-heading">
                            <h6>Features</h6>
                        </div>
                        <div className="bottom-content">
                            <div className="single-card">
                                <img src="\assets\grant\feature-cardbg.png" alt="img" className='img-fluid feature-cardbg' />
                                <h6>Alignment with vion Mission:</h6>
                                <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                            </div>
                            <div className="single-card">
                                <img src="\assets\grant\feature-cardbg.png" alt="img" className='img-fluid feature-cardbg' />
                                <h6>Technical Feasibility:</h6>
                                <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                            </div>
                            <div className="single-card">
                                <img src="\assets\grant\feature-cardbg.png" alt="img" className='img-fluid feature-cardbg' />
                                <h6>Innovation:</h6>
                                <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                            </div>
                            <div className="single-card">
                                <img src="\assets\grant\feature-cardbg.png" alt="img" className='img-fluid feature-cardbg' />
                                <h6>Community Impact:</h6>
                                <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third-sec">
                    <img src="\assets\grant\application-bg.png" alt="img" className='img-fluid application-bg' />
                    <div className="custom-container">
                        <div className="main-heading">
                            <h4>Application Process</h4>
                        </div>
                        <div className="bottom-roadmap">
                            <div className="left-heading">
                                <div className="first-heading common-heading-style">
                                    <h4>Prepare Your Proposal:</h4>
                                </div>
                                <div className="sec-heading common-heading-style">
                                    <h4>Submit Your Application:</h4>
                                </div>
                                <div className="third-heading common-heading-style">
                                    <h4>Review Process:</h4>
                                </div>
                                <div className="sec-heading common-heading-style">
                                    <h4>Funding Decision:</h4>
                                </div>
                            </div>
                            <div className="middle-line">
                                <img src="\assets\grant\middle-roadmap-line.png" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-detail">
                                <div className="single-detail">
                                    <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                        <br /> <br />
                                        Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                                </div>
                                <div className="single-detail">
                                    <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                        <br /> <br />
                                        Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                                </div>
                                <div className="single-detail">
                                    <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                        <br /> <br />
                                        Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                                </div>
                                <div className="single-detail">
                                    <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                        <br /> <br />
                                        Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-roadmap-mobile d-none">
                            <div className="single-content">
                                <div className="left-img">
                                    <img width={40} src="\assets\grant\prepare.svg" alt="img" className='img-fluid' />
                                </div>
                                <div className="right-text">
                                    <h4>Prepare Your Proposal:</h4>
                                    <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                        <br /> <br />
                                        Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                                </div>
                            </div>
                            <div className="single-content">
                                <div className="left-img">
                                    <img width={40} src="\assets\grant\submit.svg" alt="img" className='img-fluid' />
                                </div>
                                <div className="right-text">
                                    <h4>Submit Your Application:</h4>
                                    <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                        <br /> <br />
                                        Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                                </div>
                            </div>
                            <div className="single-content">
                                <div className="left-img">
                                    <img width={40} src="\assets\grant\review.svg" alt="img" className='img-fluid' />
                                </div>
                                <div className="right-text">
                                    <h4>Review Process:</h4>
                                    <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                        <br /> <br />
                                        Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                                </div>
                            </div>
                            <div className="single-content">
                                <div className="left-img">
                                    <img width={40} src="\assets\grant\funding.svg" alt="img" className='img-fluid' />
                                </div>
                                <div className="right-text">
                                    <h4>Funding Decision:</h4>
                                    <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                        <br /> <br />
                                        Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fourth-section">
                    <img src="\assets\grant\support-center-top.png" alt="img" className='img-fluid support-bg' />
                    <div className="custom-container">
                        <div className="main-heading">
                            <h6>Support and Resources</h6>
                            <p>In addition to financial support, the DOP Grant Program will provide grantees with access to various resources, including:</p>
                        </div>
                        <div className="bottom-content">
                            <div className="single-text">
                                <h6><span>01. </span>Technical guidance and mentorship from our experienced team</h6>
                            </div>
                            <div className="single-text">
                                <h6><span>02. </span>Networking opportunities within the DOP community</h6>
                            </div>
                            <div className="single-text">
                                <h6><span>03. </span>Promotional support and visibility through our channels</h6>
                            </div>
                            <div className="single-text">
                                <h6><span>04. </span>Access to educational materials and documentation</h6>
                            </div>
                        </div>
                        <p className="para">We encourage all interested parties to explore our grant program and contribute to the development of a more secure, transparent, and user-centric data ownership ecosystem.</p>
                    </div>
                </div>
                <Build />
                <Footer />
            </section>
        </>
    )
}

export default Grant
