import React, { useEffect, useRef, useState } from "react";
import "./applyviongrant.scss";
import ContactDetail from "./ContactDetail";
import ProjectOverview from "./ProjectOverview";
import ProjectSpecifics from "./ProjectSpecifics";
import AditionalInfo from "./AditionalInfo";
import Finalize from "./Finalize";
import Navbar from "../landing/header/Navbar";
import Footer from "../landing/footer/Footer";



const ApplyForVion = () => {
    const scrollContainerRef = useRef(null);



    const [checkforms, setCheckForms] = useState(() => {
        const saved = localStorage.getItem('checkforms');
        return saved !== null ? JSON.parse(saved) : 0;
    });

    const [currentStep, setCurrentStep] = useState(() => {
        const savedCurrentStep = localStorage.getItem('currentStep');
        return savedCurrentStep !== null ? JSON.parse(savedCurrentStep) : 0;
    });

    useEffect(() => {
        localStorage.setItem('checkforms', JSON.stringify(checkforms));
    }, [checkforms]);

    useEffect(() => {
        localStorage.setItem('currentStep', JSON.stringify(currentStep));
    }, [currentStep]);

    const positions = [0, 180, 350, 500, 900];

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                left: positions[currentStep],
                behavior: 'smooth',
            });
        }
    }, [currentStep]);

    const handleStepClick = (step) => {
        const savedCheckforms = JSON.parse(localStorage.getItem('checkforms')) || 0;
        if (step <= savedCheckforms) {
            setCurrentStep(step);
        }
    };

    return (
        <>
            <Navbar />
            <section className="maincompany applyforvion">
                <div className="top-head">
                    <img src="\assets\grant\head-bg.png" alt="img" className='img-fluid head-bg' />
                    <div className="custom-container">
                        <h6><span>VION</span> Grants</h6>
                    </div>
                </div>
            </section>
            <section className="applyforvion-section">
                <img src="\assets\grant\applyforvion-bg.png" alt="img" className="img-fluid applyforvion-bg" />
                <img src="\assets\grant\applyforvion-bottom.png" alt="img" className="img-fluid applyforvion-bottom" />
                <div className="custom-container">
                    <div className="parent-applicationform">
                        <div className="left-side">
                            <div className="selected-content" ref={scrollContainerRef}>
                                <div className="single-content" onClick={() => handleStepClick(0)}>
                                    <div className={currentStep == 0 ? "num-card active" : "num-card"}>
                                        <p>1</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 0 ? "active" : ""}>Contact <br /> DETAILS</h6>
                                    </div>
                                </div>
                                <div className="single-content" onClick={() => handleStepClick(1)}>
                                    <div className={currentStep == 1 ? "num-card active" : "num-card"}>
                                        <p>2</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 1 ? "active" : ""}>Project <br /> OVERVIEW</h6>
                                    </div>
                                </div>
                                <div className="single-content" onClick={() => handleStepClick(2)}>
                                    <div className={currentStep == 2 ? "num-card active" : "num-card"}>
                                        <p>3</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 2 ? "active" : ""}>Project <br /> Specifics</h6>
                                    </div>
                                </div>
                                <div className="single-content" onClick={() => handleStepClick(3)}>
                                    <div className={currentStep == 3 ? "num-card active" : "num-card"}>
                                        <p>4</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 3 ? "active" : ""}>Additional <br /> Information</h6>
                                    </div>
                                </div>
                                <div className="single-content" onClick={() => handleStepClick(4)}>
                                    <div className={currentStep == 4 ? "num-card active" : "num-card"}>
                                        <p>5</p>
                                    </div>
                                    <div className="text-side">
                                        <h6 className={currentStep == 4 ? "active" : ""}>fINALIZE <br /> APPLICATION</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="main-applicationfrom">
                                {
                                    currentStep == 0 ? <ContactDetail setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                        : currentStep == 1 ? <ProjectOverview setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                            : currentStep == 2 ? <ProjectSpecifics setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                                : currentStep == 3 ? <AditionalInfo setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                                    : <Finalize setCheckForms={(step) => { setCheckForms(step); setCurrentStep(step); }} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>
    );
};

export default ApplyForVion;
