import React from 'react'
import Navbar from '../landing/header/Navbar'
import Build from '../landing/Buildthefuture/Build'
import "./privacy.scss";

import Footer from '../landing/footer/Footer'
const Privacy = () => {
  return (
    <>

    <Navbar />
    <section className="privacy-section">
        <div className="custom-container">
            <div className='inner_pro'>
                <h1>Privacy & Policy</h1>
                <img src='\assets\planets.png' className='planets' />
            </div>
            <div className='innner_new'>
                <p>Lorem ipsum odor amet, consectetuer adipiscing elit. Fringilla magnis dictum sem fermentum facilisi per nascetur diam! Feugiat praesent condimentum gravida quam iaculis sodales velit. Dignissim odio accumsan interdum lorem vulputate ante; habitant tortor. Lobortis quis auctor nam; magnis sodales torquent. Nostra at augue netus placerat molestie.</p>
                <p>Efficitur amet fusce platea accumsan mattis curae? Nec nec vehicula sodales posuere porttitor molestie. Massa volutpat congue ut fermentum commodo feugiat.<span>Elit tellus felis</span> malesuada mi tincidunt facilisis vulputate. Id diam sed integer urna platea ac. Vulputate fusce eros mi nulla ultricies volutpat, laoreet sit. Volutpat torquent interdum dis nisi fames velit nascetur orci. Odio venenatis pellentesque blandit praesent efficitur blandit euismod. Faucibus accumsan nec taciti vehicula nullam.</p>
                <p>At praesent consectetur elit etiam quam vitae. Morbi litora dictum iaculis enim lectus pretium. Dis hendrerit cras platea facilisis a rutrum. Tristique ligula vivamus habitasse imperdiet fringilla dapibus. Aliquet cursus convallis nisi rhoncus tortor neque. Placerat praesent maecenas; primis et eu tincidunt mattis. Aenean dis luctus magnis; potenti augue amet velit. Sed viverra adipiscing feugiat velit parturient phasellus sollicitudin ullamcorper? Facilisis eros nostra dis laoreet orci. Est suscipit turpis id bibendum hendrerit maximus diam potenti. Augue facilisis penatibus imperdiet ornare interdum. Donec eleifend at curae risus risus imperdiet! Dolor consectetur luctus ante eget nascetur fusce magna duis risus. Facilisis ipsum dis platea hendrerit massa molestie bibendum rhoncus.</p>
                <p>Justo molestie orci habitant vehicula sodales phasellus.<span>Scelerisque ridiculus </span> fusce nisi in platea. Sem conubia arcu eleifend aptent adipiscing. Porttitor erat ornare maximus praesent lacus curabitur penatibus ante sapien. Et integer orci fusce pellentesque proin vitae natoque. Lobortis praesent rutrum praesent elementum arcu. Interdum nisi curabitur augue sit nam sapien eros ac pulvinar?</p>
                <p>Posuere condimentum sodales cras vulputate fermentum condimentum eu. Ex luctus praesent vestibulum; vulputate eu maecenas! Elit nostra dictum orci amet erat faucibus dictum vitae. Posuere aliquam ut conubia laoreet sit.<span>Lacinia mattis</span>  hac viverra dui adipiscing nisl. Lacinia cursus auctor vulputate viverra magna aenean hac. Quis netus in varius leo enim. Facilisi quis ex aptent eget malesuada tempus ultrices augue dui.</p>
                <h3>Lorem molestie sapien montes molestie, laoreet viverra cursus purus eros senectus velit habitasse taciti.</h3>
                <p>Eros vehicula ex sagittis donec risus tellus imperdiet. Etiam integer praesent cubilia consectetur hac urna. Augue turpis molestie dis taciti non. Feugiat semper aenean integer <span>curabitur dolor potenti</span> . Quis faucibus eget elit eros ultricies ligula. Lorem molestie sapien montes molestie, laoreet viverra. Cursus purus eros senectus velit habitasse taciti. Eros vehicula ex sagittis donec risus tellus imperdiet. Etiam integer praesent cubilia consectetur hac urna. Augue turpis molestie dis taciti non. Feugiat semper aenean integer curabitur dolor potenti. Quis faucibus eget elit eros ultricies ligula.</p>
                <p><span>Nunc ullamcorper</span> nulla lacinia odio imperdiet. Gravida sodales magna ad vehicula dictum platea quam. Nostra rutrum eros consequat ante libero fermentum efficitur eget pulvinar. Arcu netus laoreet aptent feugiat, curabitur nisl efficitur morbi. Montes facilisis consequat placerat tincidunt pulvinar. Efficitur erat diam felis dapibus turpis mollis. Leo magna consequat augue felis finibus! Ut lacinia accumsan auctor pharetra adipiscing lacinia inceptos. Odio habitasse ut odio cubilia ex velit vel nulla.</p>
                <p>Etiam quis mollis integer; augue sapien orci.<span> Rhoncus himenaeos</span>  ante dignissim pellentesque sem dictum vulputate risus. Donec ante accumsan ligula lacinia vitae nunc cubilia maximus. Augue morbi facilisi lacinia hac euismod morbi. Magnis porttitor cras est luctus ac ex metus sociosqu. Mi imperdiet massa sapien aliquam posuere mi lobortis. Purus nascetur rhoncus et parturient sem tempor a. Erat porta imperdiet luctus mi consectetur gravida duis mus. Facilisis erat purus proin orci eros quisque iaculis! Torquent fames ut erat ultrices aenean. Erat feugiat senectus vel quam sapien bibendum.</p>
                <p>Tempor condimentum aliquam praesent quisque aliquam erat nostra. Justo posuere mauris fermentum euismod nec habitasse porta parturient. Massa sollicitudin ipsum viverra parturient inceptos varius mauris accumsan. Interdum nunc nullam habitasse maximus velit ultrices. Mollis ornare conubia natoque fusce euismod dolor inceptos. Laoreet inceptos mattis hendrerit viverra primis per.</p>
                <p>Dictumst non <span> volutpat varius varius</span>  molestie semper. Auctor malesuada natoque in natoque mus velit. Cras aptent faucibus vivamus habitasse ad potenti volutpat.</p>
                <p>Neque a adipiscing purus curae litora arcu maecenas tincidunt egestas nunc luctus parturient.</p>
            </div>
        </div>
    </section>
    <Build />
    <Footer />
</>
  )
}

export default Privacy