

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Environment from '../../utils/Environment';

const AditionalInfo = ({ setCheckForms }) => {
    const [integrationDetail, setIntegrationDetail] = useState('');
    const [pitchDeckUrl, setPitchDeckUrl] = useState('');
    const [documents, setDocuments] = useState([{ url: '', description: '' }]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('applyForm'));
        if (storedData && storedData.additionalInformation) {
            const { additionalInformation } = storedData;
            setIntegrationDetail(additionalInformation.integrationDetail || '');
            setPitchDeckUrl(additionalInformation.attachmentUrl || '');
            setDocuments(additionalInformation.publicUrl?.map(url => ({ url, description: '' })) || [{ url: '', description: '' }]);
        }
    }, []);

    const handleAddDocument = () => {
        setDocuments([...documents, { url: '', description: '' }]);
    };

    const handleRemoveDocument = (index) => {
        setDocuments(documents.filter((_, i) => i !== index));
    };

    const handleDocumentChange = (index, field, value) => {
        const newDocuments = documents.map((doc, i) => {
            if (i === index) {
                return { ...doc, [field]: value };
            }
            return doc;
        });
        setDocuments(newDocuments);
    };

    const validateURL = (url) => {
        const regex = /^(ftp|http|https):\/\/[^ "]+$/;
        return regex.test(url);
    };

    const validateFields = () => {
        let errors = {};

        if (!integrationDetail) errors.integrationDetail = 'Please provide any additional details about your project and its integration with DOP';
       else  if (!pitchDeckUrl) errors.pitchDeckUrl = 'Please provide the URL to your pitch deck';
        else if (!validateURL(pitchDeckUrl)) errors.pitchDeckUrl = 'Please provide a valid URL';

        // documents.forEach((doc, index) => {
        //     if (!doc.url) {
        //         errors[`docUrl${index}`] = 'Please provide the public URL(s) to your document';
        //     } else if (!validateURL(doc.url)) {
        //         errors[`docUrl${index}`] = 'Please provide a valid URL';
        //     }
        //  else   if (!doc.description) {
        //         errors[`docDescription${index}`] = 'Description is required';
        //     }
        // }
        // );

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateFields()) return;

        const additionalInformation = {
            integrationDetail,
            attachmentUrl: pitchDeckUrl,
            publicUrl: documents.map(doc => doc.url).filter(url => url),
        };

        const previousData = JSON.parse(localStorage.getItem('applyForm')) || {};
        const data = {
            ...previousData,
            additionalInformation: additionalInformation,
        };

        localStorage.setItem('applyForm', JSON.stringify(data));

        // const config = {
        //   method: 'put',
        //   url: Environment.api_url2 + '/application',
        //   data: data,
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        // };

        // try {
        //   await axios(config);
        setCheckForms(4);
        // } catch (error) {
        //   console.error(error);
        //   // Handle error appropriately
        // }
    };

    return (
        <section className="project-overview common-box-style">
            <div className="main-heading">
                <h6>Additional Information</h6>
            </div>
            <div className="technical-details">
                <div className="option-field">
                    <h6>Is there anything else you would like to share about your project and its integration with DOP?</h6>
                    <div className="material-textfield">
                    <label className='label-textarea'>Your answer here</label>
                        <textarea
                            placeholder="Provide detailed description"
                            type="text"
                            value={integrationDetail}
                            onChange={(e) => { setIntegrationDetail(e.target.value); setErrors({ ...errors, integrationDetail: '' }) }}
                        />
                        
                        {errors.integrationDetail && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.integrationDetail}</p>}
                    </div>
                </div>
            </div>
            <div className="team-information">
                <h6 className='head-text'>Attachments</h6>
                <div className="option-field">
                    <div className="material-textfield">
                    <label>YOUR PITCH DECK https://example.com</label>
                        <input
                            placeholder="Enter URL"
                            type="text"
                            value={pitchDeckUrl}
                            onChange={(e) => { setPitchDeckUrl(e.target.value); setErrors({ ...errors, pitchDeckUrl: '' }) }}
                        />
                       
                       
                    </div>
                    {errors.pitchDeckUrl && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors.pitchDeckUrl}</p>}
                </div>
            </div>
            <div className="key-teammembers">
                <h6 className="head-text">Any additional supporting documents (optional).</h6>
                {documents.map((doc, index) => (
                    <div className="single-keymember" key={index}>
                        <h6>Additional DOCUMENT {index + 1}</h6>
                        <div className="option-field">
                            <div className="material-textfield">
                            <label>PUBLIC URL TO YOUR DOCUMENT</label>   
                                <input
                                    placeholder="Enter URL"
                                    type="text"
                                    value={doc.url}
                                    onChange={(e) => { handleDocumentChange(index, 'url', e.target.value); setErrors({ ...errors, [`docUrl${index}`]: '' }) }}
                                />
                                                         
                            </div>
                            {errors[`docUrl${index}`] && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors[`docUrl${index}`]}</p>}
                        </div>
                        <div className="option-field">
                            <div className="material-textfield">
                            <label className='label-textarea'>Description</label>
                                <textarea
                                    placeholder="Enter Description"
                                    type="text"
                                    value={doc.description}
                                    onChange={(e) => { handleDocumentChange(index, 'description', e.target.value); setErrors({ ...errors, [`docDescription${index}`]: '' }) }}
                                />
                                
                            
                            </div>
                            {errors[`docDescription${index}`] && <p className="error-text" style={{ color: 'red', fontSize: '0.875rem' }}>{errors[`docDescription${index}`]}</p>}
                        </div>
                        {documents.length > 1 && index !== 0 && (
                        <button className='btn-remove' onClick={() => handleRemoveDocument(index)}>Remove</button>
                        )}
                    </div>
                ))}
                <button className='btn-another' onClick={handleAddDocument}>Add DOCUMENT</button>
            </div>
            <div className="bottom-btns">
                <button onClick={() => setCheckForms(2)} className="btn-backk">
                 
                    <h6>Back</h6>
                </button>
                <button onClick={handleSubmit} className="btn-blackk">
                    <h6>Next</h6>
                 
                </button>
            </div>
        </section>
    );
};

export default AditionalInfo;

